import {
  Box,
  Stack,
  useBoolean,
  useFormControlContext,
  useToken,
} from '@chakra-ui/react';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';
import {
  DraftHandleValue,
  EditorState,
  RawDraftContentState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import * as React from 'react';

import { useHandlePastedFiles } from '@/hooks/useHandlePastedFiles';
import { Loading } from '@/imports/ui/chakra/components/Loading';
import { prose } from '@/imports/ui/chakra/components/Prose/Prose';
import { createLinkPlugin } from '@/modules/draft-js-link-plugin';

import { Toolbar } from './components/Toolbar';
import { splitBlock } from './utils';

const linkPlugin = createLinkPlugin();
const imagePlugin = createImagePlugin();

export const RichTextEditor = React.forwardRef<
  Editor,
  {
    value: RawDraftContentState | null;
    onChange?: (value: RawDraftContentState) => void;
    onBlur?: () => void;
    isDisabled?: boolean;
    placeholder?: string;
  }
>((props, ref) => {
  const [blue500] = useToken('colors', ['blue.500']);
  const { getUrlsFromFiles, isUploading } = useHandlePastedFiles();
  const internalRef = React.useRef<Editor>(null);
  const [isFocused, setIsFocused] = useBoolean();
  const formControlContext = useFormControlContext();
  const [isPasting, setIsPasting] = useBoolean(false);
  const isLoading = isPasting || isUploading;
  const isDisabled =
    props.isDisabled || formControlContext?.isDisabled || isLoading;

  const [editorState, setEditorState] = React.useState(
    props.value
      ? EditorState.createWithContent(convertFromRaw(props.value))
      : EditorState.createEmpty()
  );

  const onChange = (editorState: EditorState) => {
    setEditorState(editorState);

    if (props.onChange) {
      props.onChange(convertToRaw(editorState.getCurrentContent()));
    }
  };

  React.useImperativeHandle<Editor | null, Editor | null>(
    ref,
    () => internalRef.current
  );

  const handlePastedFiles = (files: File[]): DraftHandleValue => {
    setIsPasting.on();
    getUrlsFromFiles(files)
      .then((urls) =>
        urls.forEach((url) => {
          setEditorState(imagePlugin.addImage(editorState, url, {}));
        })
      )
      .finally(() => setIsPasting.off());
    return 'handled';
  };

  return (
    <Stack h="full">
      <Box
        position="relative"
        overflowY="auto"
        borderRadius="md"
        border="1px"
        borderColor={isFocused ? 'blue.500' : 'inherit'}
        boxShadow={isFocused ? `0 0 0 1px ${blue500}` : undefined}
        flex="1"
        bg="white"
        sx={{
          '.DraftEditor-root': {
            h: 'full',
          },
          '.DraftEditor-editorContainer': {
            h: 'full',
          },
          '.public-DraftEditor-content': {
            p: 4,
            h: 'full',
            overflowY: 'auto',
          },
          '.public-DraftStyleDefault-block': {
            opacity: isDisabled ? 0.4 : undefined,
          },
          '.public-DraftEditorPlaceholder-root': {
            color: 'gray.500',
            p: 4,
          },
          '.text-right .public-DraftStyleDefault-block': {
            textAlign: 'right',
          },
          '.text-center .public-DraftStyleDefault-block': {
            textAlign: 'center',
          },
          '.text-left .public-DraftStyleDefault-block': {
            textAlign: 'left',
          },
          '.public-DraftStyleDefault-unorderedListItem, .public-DraftStyleDefault-orderedListItem':
            {
              marginLeft: 0,
            },
          ...prose,
        }}
      >
        <Editor
          placeholder={props.placeholder}
          blockStyleFn={(contentBlock) => {
            const textAlign = contentBlock.getData().get('textAlign');
            return textAlign ? `text-${textAlign}` : 'text-left';
          }}
          handleReturn={() => {
            // TODO: i think this is causing a weird issue
            setEditorState(splitBlock(editorState));
            return 'handled';
          }}
          ref={internalRef}
          onFocus={() => {
            setIsFocused.on();
          }}
          onBlur={() => {
            setIsFocused.off();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
          editorState={editorState}
          onChange={(editorState) => onChange(editorState)}
          readOnly={isDisabled}
          spellCheck
          handlePastedFiles={handlePastedFiles}
          plugins={[linkPlugin, imagePlugin]}
        />
        {isLoading && (
          <Loading
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="whiteAlpha.700"
            zIndex="docked"
          />
        )}
      </Box>
      <Toolbar
        editorState={editorState}
        setEditorState={onChange}
        isDisabled={isDisabled}
      />
    </Stack>
  );
});

RichTextEditor.displayName = 'RichTextEditor';
