import { Stack, Wrap } from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import * as React from 'react';

import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  ItalicIcon,
  ListIcon,
  UnderlineIcon,
} from '@/imports/ui/chakra/feather';
import { NumberedListIcon } from '@/imports/ui/components/TextEditor/components/Toolbar/components/NumberedListIcon';
import { AddLinkButton } from '@/modules/draft-js-link-plugin';

import { BlockTypeButton } from './components/BlockTypeButton';
import { BlockTypeSelect } from './components/BlockTypeSelect';
import { InlineStyleButton } from './components/InlineStyleButton';
import { TextAlignmentButton } from './components/TextAlignmentButton';

export const Toolbar: React.FC<{
  editorState: EditorState;
  setEditorState: (editorState: EditorState) => void;
  isDisabled?: boolean;
}> = (props) => {
  return (
    <Stack>
      <Wrap shouldWrapChildren>
        <BlockTypeSelect
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          isDisabled={props.isDisabled}
        />
        <InlineStyleButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          inlineStyle="BOLD"
          icon={<BoldIcon />}
          aria-label="bold text"
          isDisabled={props.isDisabled}
        />
        <InlineStyleButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          inlineStyle="ITALIC"
          icon={<ItalicIcon />}
          aria-label="italic text"
          isDisabled={props.isDisabled}
        />
        <InlineStyleButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          inlineStyle="UNDERLINE"
          icon={<UnderlineIcon />}
          aria-label="underline text"
          isDisabled={props.isDisabled}
        />
        <BlockTypeButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          blockType="ordered-list-item"
          icon={<NumberedListIcon />}
          aria-label="ordered list"
          isDisabled={props.isDisabled}
        />
        <BlockTypeButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          blockType="unordered-list-item"
          icon={<ListIcon />}
          aria-label="unordered list"
          isDisabled={props.isDisabled}
        />
        <TextAlignmentButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          textAlign="left"
          icon={<AlignLeftIcon />}
          aria-label="align left"
          isDisabled={props.isDisabled}
        />
        <TextAlignmentButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          textAlign="center"
          icon={<AlignCenterIcon />}
          aria-label="align center"
          isDisabled={props.isDisabled}
        />
        <TextAlignmentButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          textAlign="right"
          icon={<AlignRightIcon />}
          aria-label="align right"
          isDisabled={props.isDisabled}
        />
        <AddLinkButton
          editorState={props.editorState}
          setEditorState={props.setEditorState}
          aria-label="link"
          variant="outline"
          colorScheme="gray"
          size="sm"
          submitButtonColorScheme="purple"
          isDisabled={props.isDisabled}
        />
      </Wrap>
    </Stack>
  );
};
