import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import * as React from 'react';

export const InlineStyleButton: React.FC<
  {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    inlineStyle:
      | 'BOLD'
      | 'ITALIC'
      | 'UNDERLINE'
      | 'ordered-list-item'
      | 'unordered-list-item';
  } & IconButtonProps
> = ({ editorState, setEditorState, inlineStyle, ...props }) => {
  return (
    <Box onMouseDown={(event) => event.preventDefault()}>
      <IconButton
        size="sm"
        variant="outline"
        colorScheme="gray"
        isActive={editorState.getCurrentInlineStyle().has(inlineStyle)}
        onClick={(event) => {
          event.preventDefault();
          setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
        }}
        {...props}
      />
    </Box>
  );
};
