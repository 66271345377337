import {
  Box,
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import * as React from 'react';

import { getCurrentlySelectedBlock } from '@/components/RichTextEditor/utils';
import { SelectButton } from '@/components/SelectButton';
import { CheckIcon } from '@/imports/ui/chakra/feather';

type BlockType =
  | 'unstyled'
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'blockquote';

const BLOCK_TYPE_LABELS = {
  unstyled: 'Paragraph',
  'header-one': 'Heading 1',
  'header-two': 'Heading 2',
  'header-three': 'Heading 3',
  blockquote: 'Block Quote',
};

const BlockTypeOption: React.FC<
  {
    blockType: BlockType;
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    onClose: () => void;
  } & ButtonProps
> = ({ blockType, editorState, setEditorState, onClose, ...props }) => {
  const currentBlockType =
    getCurrentlySelectedBlock(editorState).currentBlock.getType();

  return (
    <Button
      justifyContent="flex-start"
      variant="ghost"
      colorScheme="gray"
      leftIcon={currentBlockType === blockType ? <CheckIcon /> : undefined}
      onMouseOver={(event) => event.preventDefault()}
      onMouseDown={(event) => {
        event.preventDefault;
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
        onClose();
      }}
      {...props}
    >
      {BLOCK_TYPE_LABELS[blockType]}
    </Button>
  );
};

export const BlockTypeSelect: React.FC<{
  editorState: EditorState;
  setEditorState: (value: EditorState) => void;
  blockTypes?: BlockType[];
  isDisabled?: boolean;
}> = ({
  editorState,
  setEditorState,
  blockTypes = [
    'unstyled',
    'header-one',
    'header-two',
    'header-three',
    'blockquote',
  ],
  isDisabled,
}) => {
  const ref = React.useRef(null);
  const menu = useDisclosure();

  // need to use outside click explicitly here because the menu button is
  // controlled. the menu button is controlled so that the rich text editor
  // doesn't lose its selection state and focus when you click the menu
  // button
  useOutsideClick({
    ref,
    handler: menu.onClose,
  });

  const value = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType();

  return (
    <Box ref={ref}>
      <Menu isOpen={menu.isOpen} autoSelect={false} placement="bottom">
        <MenuButton
          as={SelectButton}
          onMouseDown={(event) => {
            event.preventDefault();
            menu.onToggle();
          }}
          size="sm"
          isDisabled={isDisabled}
        >
          {BLOCK_TYPE_LABELS[value as BlockType]}
        </MenuButton>
        <MenuList
          display="flex"
          flexDirection="column"
          onMouseDown={(event) => event.preventDefault()}
        >
          {blockTypes.map((blockType) => (
            <BlockTypeOption
              key={blockType}
              editorState={editorState}
              setEditorState={setEditorState}
              blockType={blockType}
              onClose={menu.onClose}
            />
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};
