// https://gist.github.com/joshdover/7c5e61ed68cc5552dc8a25463e357960
import { EditorState, Modifier } from 'draft-js';
import * as Immutable from 'immutable';

export const getCurrentlySelectedBlock = (editorState: EditorState) => {
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  let endKey = selection.getEndKey();
  const content = editorState.getCurrentContent();
  let target = selection;

  // Triple-click can lead to a selection that includes offset 0 of the
  // following block. The `SelectionState` for this case is accurate, but
  // we should avoid toggling block type for the trailing block because it
  // is a confusing interaction.
  if (startKey !== endKey && selection.getEndOffset() === 0) {
    const blockBefore = content.getBlockBefore(endKey);

    if (!blockBefore) {
      throw new Error('Got unexpected null or undefined');
    }

    endKey = blockBefore.getKey();
    target = target.merge({
      anchorKey: startKey,
      anchorOffset: selection.getStartOffset(),
      focusKey: endKey,
      focusOffset: blockBefore.getLength(),
      isBackward: false,
    });
  }

  const hasAtomicBlock = content
    .getBlockMap()
    .skipWhile((_, k) => k !== startKey)
    .takeWhile((_, k) => k !== endKey)
    .some((v) => (v ? v.getType() === 'atomic' : false));

  const currentBlock = content.getBlockForKey(startKey);

  return {
    content,
    currentBlock,
    hasAtomicBlock,
    target,
  };
};

export const toggleAlignment = (
  editorState: EditorState,
  textAlign: 'left' | 'right' | 'center'
) => {
  const { content, currentBlock, hasAtomicBlock, target } =
    getCurrentlySelectedBlock(editorState);

  if (hasAtomicBlock) {
    return editorState;
  }

  const blockData = currentBlock.getData();

  return EditorState.push(
    editorState,
    Modifier.mergeBlockData(
      content,
      target,
      Immutable.Map({
        textAlign:
          blockData && blockData.get('textAlign') === textAlign
            ? undefined
            : textAlign,
      })
    ),
    'change-block-data'
  );
};

export const splitBlock = (editorState: EditorState) => {
  const contentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );
  const splitState = EditorState.push(editorState, contentState, 'split-block');
  const { currentBlock } = getCurrentlySelectedBlock(editorState);
  const alignment = currentBlock.getData().get('textAlign');
  if (alignment) {
    return toggleAlignment(splitState, alignment);
  } else {
    return splitState;
  }
};
