import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { EditorState, RichUtils } from 'draft-js';
import * as React from 'react';

import { getCurrentlySelectedBlock } from '@/components/RichTextEditor/utils';

export const BlockTypeButton: React.FC<
  {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    blockType: 'ordered-list-item' | 'unordered-list-item';
  } & IconButtonProps
> = ({ editorState, setEditorState, blockType, ...props }) => {
  const currentBlockType =
    getCurrentlySelectedBlock(editorState).currentBlock.getType();

  return (
    <Box onMouseDown={(event) => event.preventDefault()}>
      <IconButton
        size="sm"
        variant="outline"
        colorScheme="gray"
        isActive={currentBlockType === blockType}
        onClick={(event) => {
          event.preventDefault();
          setEditorState(RichUtils.toggleBlockType(editorState, blockType));
        }}
        {...props}
      />
    </Box>
  );
};
