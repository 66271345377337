import { Box, IconButton, IconButtonProps } from '@chakra-ui/react';
import { EditorState } from 'draft-js';
import * as React from 'react';

import {
  getCurrentlySelectedBlock,
  toggleAlignment,
} from '@/components/RichTextEditor/utils';

// TODO: default text alignment of the editor state is to be undefined
// which we render as left aligned by default. this makes the form state dirty
// if you toggle to something other than left aligned and then back to left
// aligned because it is explicitly "left" now instead of "undefined"

export const TextAlignmentButton: React.FC<
  {
    editorState: EditorState;
    setEditorState: (editorState: EditorState) => void;
    textAlign: 'left' | 'right' | 'center';
  } & IconButtonProps
> = ({ editorState, setEditorState, textAlign, ...props }) => {
  const { currentBlock } = getCurrentlySelectedBlock(editorState);
  const currentTextAlign = currentBlock.getData().get('textAlign');
  const currentBlockType = currentBlock.getType();

  const isAlignable = [
    'unstyled',
    'header-one',
    'header-two',
    'header-three',
  ].includes(currentBlockType);

  return (
    <Box onMouseDown={(event) => event.preventDefault()}>
      <IconButton
        size="sm"
        variant="outline"
        colorScheme="gray"
        isDisabled={!isAlignable}
        isActive={
          isAlignable &&
          (currentTextAlign
            ? currentTextAlign === textAlign
            : textAlign === 'left')
        }
        onClick={() => setEditorState(toggleAlignment(editorState, textAlign))}
        {...props}
      />
    </Box>
  );
};
